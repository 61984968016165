 <template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('branches_of_business') }} <span class="text-md"> - {{ $t('companies') }}</span> </h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active"><router-link :to="{ name: 'dashReports'}">{{ $t('dashboard') }}</router-link></li>
                     <li class="breadcrumb-item">{{ $t('branches_of_business') }}</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <!-- /.row -->
            <div class="row">
               <div class="col-12">
                  <div class="card">
                     <div class="card-header">
                        <router-link
                           :to="{ name: 'branchesCreate' }"
                           class="btn btn-success"
                        >
                           <i class="fas fa-plus-circle"></i> {{ $t('create_category') }}
                        </router-link>
                        <div class="card-tools">
                           <div class="input-group input-group-sm pt-2" style="width: 350px;">
                           <input
                              type="text"
                              name="table_search"
                              class="form-control float-right"
                              placeholder="Buscar"
                              v-model="searchQuery"
                              @input="handleDelayedSearch"
                           >
                           <div class="input-group-append">
                              <button type="submit" class="btn btn-default">
                              <i class="fas fa-search"></i>
                              </button>
                           </div>
                        </div>
                        </div>
                     </div>
                     <!-- /.card-header -->

                     <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else class="card-body table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                           <thead>
                           <tr>
                              <th>{{ $t('image') }}</th>
                              <th>{{ $t('title') }}</th>
                              <th>{{ $t('created_at') }}</th>
                              <th>{{ $t('action') }}</th>
                           </tr>
                           </thead>
                           <tbody>
                           <tr v-for="(branch) in branches" :key="branch.uuid">
                              <td>
                                 <div class="col-4">
                                    <div class="timeline-item">
                                       <div class="timeline-body">
                                          <img
                                             :src="branch.imageUrl !== '' && branch.imageUrl !== null ? branch.imageUrl : 'https://placehold.it/150x100'"
                                             width="150"
                                             height="100"
                                             alt="..."
                                          >
                                       </div>
                                    </div>
                                 </div>
                              </td>
                              <td>{{ branch.name }}</td>
                              <td>{{ branch.createdAt }}</td>
                              <td>
                                 <div class="row">
                                    <router-link
                                       :to="{ name: 'branchesEdit', params: { uuid: branch.uuid } }"
                                       class="btn btn-sm btn-outline-primary text-bold"
                                    >
                                       <i class="pt-1 fas fa-pencil-alt"></i>
                                       {{ $t('edit') }}
                                    </router-link>

                                    <button
                                       class="ml-3 btn btn-sm btn-outline-danger text-bold"
                                       @click="showConfirmationModal(branch.uuid)"
                                    >
                                       <i class="pt-1 fas fa-trash-alt"></i>
                                       {{ $t('exclude') }}
                                    </button>
                                 </div>
                              </td>
                              <ConfirmationModal
                                 ref="modalConfirmation"
                                 :modalId="modalConfirmationActionId"
                                 @actionConfirmed="deleteBranch"
                                 @actionCancelled="$(`#${modalConfirmationActionId}`).modal('hide');"
                              />
                           </tr>
                           </tbody>
                        </table>
                     </div>
                     <!-- /.card-body -->

                     <div class="card-footer clearfix">
                        <pagination
                           v-if="!isSearching"
                           :totalPages="totalPages"
                           :currentPage="currentPage"
                           :isFirstPage="isFirstPage"
                           :isLastPage="isLastPage"
                           :adjacentPages="adjacentPages"
                           :tokenPages="tokenPages"
                           :isLoading="isLoadingPagination"
                           @page-selected="handlePageSelected"
                        />
                     </div>
                     <!-- /.card-footer -->

                  </div>
                  <!-- /.card -->
               </div>
            </div>
            <!-- /.row -->
         </div>
      </section>
      <!-- /.content -->
   </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import ConfirmationModal from "../../../components/ConfirmationModal.vue";
import Pagination from "../../../components/Pagination.vue";
import { useToast } from "vue-toastification";
import branchService from "../services/branch-service";

export default {
   name: "Branches",
   setup() {
      const toast = useToast()
      return { toast }
   },
   components: {
      Pagination,
      ConfirmationModal
   },
   data(){
      return {
         isLoadingPagination: false,
         selectedBranchUuid: null,
         modalConfirmationActionId: "modal-confirmation-action-branch",
         searchTimeout: null,
         searchQuery: "",
         isSearching: false,
         adjacentPagesCount: 2
      }
   },
   async created() {
      this.isLoadingPagination = true;

      try {
         await this.getAllPaginatedBranches();

         if (this.tokenPages.length === 0) {
            await this.getInfoPaginationBranches();
         }

      } finally {
         this.isLoadingPagination = false;
      }
   },
   computed: {
      ...mapState({
         branches: state => state.branch.branches.data,
         isLoading: state => state.branch.branches.loading,
         currentPage: state => state.branch.branches.pagination.currentPage,
         tokenPages: state => state.branch.branches.pagination.tokenPages,
         totalPages: state => state.branch.branches.pagination.totalPages
      }),
      adjacentPages() {
         const currentIndex = this.tokenPages.findIndex((page) => page.page === this.currentPage);

         const startIdx = Math.max(0, currentIndex - this.adjacentPagesCount);
         const endIdx = Math.min(this.tokenPages.length - 1, currentIndex + this.adjacentPagesCount);

         const pages = this.tokenPages.slice(startIdx, endIdx + 1);

         return pages;
      },
      isFirstPage() {
         return this.currentPage === 1;
      },
      isLastPage() {
         return this.currentPage === this.totalPages;
      }
   },
   methods: {
      ...mapActions(['getAllPaginatedBranches', 'getInfoPaginationBranches', 'searchBranches']),
      ...mapMutations({
         setCurrentPage: 'SET_BRANCH_CURRENT_PAGE'
      }),
      async deleteBranch () {

         try {
            await branchService.destroy(this.selectedBranchUuid);

            $(`#${this.modalConfirmationActionId}`).modal('hide');

            await this.getAllPaginatedBranches();

            this.toast.success(this.$t("excluded"));
         } catch (error) {
            const { status } = error.response;

            $(`#${this.modalConfirmationActionId}`).modal('hide');

            this.toast.error(this.$t("exclude_failure"));
         }
      },
      async handleDelayedSearch() {
         clearTimeout(this.searchTimeout);

         this.searchTimeout = setTimeout(async () => {
            if (this.searchQuery.length > 0) {
               this.isSearching = true;
               await this.searchBranches(this.searchQuery);
            } else {
               this.isSearching = false;
               await this.getAllPaginatedBranches();
            }
         }, 1000);
      },
      async handlePageSelected (action) {
         let pageIndex;

         if (action === 'first' && !this.isFirstPage) {
            pageIndex = 1;
         } else if (action === 'prev' && !this.isFirstPage) {
            this.setCurrentPage(this.currentPage - 1);
            pageIndex = this.currentPage;
         } else if (action === 'next' && !this.isLastPage) {
            this.setCurrentPage(this.currentPage + 1);
            pageIndex = this.currentPage;
         } else if (action === 'last' && !this.isLastPage) {
            pageIndex = this.totalPages;
         } else if (typeof action === 'number') {
            pageIndex = action;
         }

         if (pageIndex) {
            const targetPage = this.tokenPages.find((page) => page.page === pageIndex);

            if (targetPage) {
               this.setCurrentPage(targetPage.page);
               await this.getAllPaginatedBranches({ pageToken: targetPage.token });
            }
         }
      },
      showConfirmationModal(uuid) {
         this.selectedBranchUuid = uuid
         $(`#${this.modalConfirmationActionId}`).modal('show')
      }
   }
}
</script>

<style scoped>
</style>
